//import vue
import Vue from 'vue';

// import vuex
import Vuex from 'vuex';
Vue.use(Vuex);

// get data
import config from './config.json';

// axios
import axios from 'axios'

// for making unique ideas (and sending them to the database)
const uuidv4 = require('uuid/v4');



export const store = new Vuex.Store({
	state: {
		rulesVersion: 'v2',
		uuid: uuidv4(),

		config: config,
		
		email: undefined,
		currentResponses: [],
	},
	getters: {
		contentAreaText: (state) => {
			if (state.currentResponses === undefined) {
				return ''
			} else if (state.currentResponses.length === 0) {
				return ''
			} else {
				const data = state.currentResponses.filter(x => x.qId === 'pre_2');
				const val = data[0].val;
				return val
			}
		},
		unansweredQuestions: (state) => {
			// array of ids that aren't yet selected
			const allQuestions = state.config.map(x => (x.questions.map(y => y.qId))).flat(1)
			const answeredQuestions = state.currentResponses.map(x => x.qId)
			const output = allQuestions.filter(x => !answeredQuestions.includes(x))
			return output
		},
		userUsesHQIM: (state) => {
			let output = null
			const resp = state.currentResponses.filter(x => x.qId === 'gateway')
			console.log(resp)
			if (resp.length > 0) {
				const val = resp[0].val
				if (val === 'Yes') {
					output = true
				} else if (val === 'No') {
					output = false
				}
			}
			console.log(output)
			return output
		},
		hasEmail: (state) => {
			return state.email !== undefined
		},
		userScores: (state) => {

			// leave it empty if there's no data
			if (state.currentResponses === undefined) {
				return []
			} else if (state.currentResponses.length === 0) {
				return []
			}

			// loop through the config to get questions
			// merge on user scores
			// score them
			// roll them up to domain scores
			const scoredDomains = state.config.filter(x => x.isScored === 1); 
			let domainScores = [];
			scoredDomains.forEach(domain => {

				// what questions are in this domain?
				const domainQuestionIds = domain.questions.map(x => x.qId)
				
				// for each question, get score and if autoRed
				let questionScores = [];
				domainQuestionIds.forEach(id => {
					
					// get the user responses to these questions
					const userQ = state.currentResponses.filter(x => x.qId === id)[0];

					// get the config info for these questions
					const configQ = domain.questions.filter(x => x.qId === id)[0];
					
					// get the score/auto red for the user's answer
					const userQ_resp = userQ.val;
					const configQ_scoring = configQ.options.filter(x => x.val === userQ_resp)[0];
					const configQ_text = configQ.text

					// score this question
					const questionScore = {
						'qId': id,
						'val': userQ_resp,
						'aRed': configQ_scoring.aRed,
						'pts': configQ_scoring.pts,
						'text': configQ_text,
						'advice': configQ_scoring.advice
					}
					questionScores.push(questionScore)
				})

				// for each domain
				const ptsEarned = questionScores.map(x => x.pts).reduce((cum, x) => cum + x, 0);
				const aRed = questionScores.map(x => x.aRed === undefined ? 0 : x.aRed).reduce((cum, x) => cum + x, 0);

				// determine colorCat (i.e., red/yellow/green)
				let colorCat = null;
				if (ptsEarned === null) {
					colorCat = null;
				} else if (aRed > 0) {
					colorCat = 'red'
				} else if (ptsEarned <= domain.redCutoff) {
					colorCat = 'red'
				} else if (ptsEarned <= domain.yellowCutoff) {
					colorCat = 'yellow'
				} else if (ptsEarned > domain.yellowCutoff) {
					colorCat = 'green'
				}

				// now get the colorCode
				let colorCode = null;
				const color_dict = {
					// red: '#dd1c1a',
					// green: '#43aa8b'
					red: '#e56e57',
					yellow: '#f3d000',
					green: '#62bab0'
				}
				colorCode = color_dict[colorCat]

				// and get the domainMessage
				const domainMessage = domain[colorCat + 'Message'];

				// send it back
				const domainScore = {
					'domain': domain.domain,
					'domainTitle': domain.domainTitle,
					'ptsEarned': ptsEarned,
					'numAutoReds': aRed,
					'colorCat': colorCat,
					'colorCode': colorCode,
					'msg': domainMessage,
					'questions': questionScores
				};
				domainScores.push(domainScore);
			})

			return domainScores
		}
	},
	mutations: {
		updateCurrentResponses: (state, value) => {
			
			// get all current responses
			let curResponses = state.currentResponses;

			// remove the current question (if it exists)
			const submittedQuestionId = value.qId
			curResponses = curResponses.filter(x => x.qId !== submittedQuestionId)

			// add the users's new value (unless it's null)
			if (value.val !== null) {
				curResponses.push(value)
			}

			// update the store
			state.currentResponses = curResponses
		},
		setCurrentResponses: (state, value) => {
			state.currentResponses = value
		},
		setEmail: (state, value) => {
			state.email = value
		},
	},
	actions: {
		// async getResponse({commit}) {
		async getResponse({ commit, state }, id) {
			
			// if you're hitting the db, you should empty out the current responses
			commit('setCurrentResponses', []);
			commit('setEmail', undefined);

			// this is what we'll send back
			let curResp = undefined;
			let curEmail = undefined;

			// hit the route
			await axios.get(process.env.VUE_APP_GET_ENDPOINT,
			{
				headers: { 'Access-Control-Allow-Origin': '*' },
				params: { 'id': id }
			})
			.then(response => {
				// see if results came back and, if they did, make sure the versions match
				// if either of those aren't true, leave the apiData as undefined
				const version = response.data.ruleset;
				curResp = state.rulesVersion === version ? response.data.responses : undefined;
				curEmail = response.data.email;
			})
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				console.log(curResp)
				console.log(curEmail)
				commit('setCurrentResponses', curResp);
				commit('setEmail', curEmail)
			})			
			return 
		},
		submitResponses({ state }) {
			
			// build the data
			const uuid = state.uuid;
			const tmstmp = new Date().getTime();
			const vals = {
				time: tmstmp,
				ruleset: state.rulesVersion,
				responses: state.currentResponses,
			}

			// put the data
			axios.put(process.env.VUE_APP_PUT_ENDPOINT, {
				headers: { 'Access-Control-Allow-Origin': '*' },
				uuid,
				data: vals
			})
		},
		submitEmail({ commit }, data) {

			// set email
			commit('setEmail', data.email);

			// get uuid
			// 	always use the id of the page your own
			const url = window.location.href;
			const uuid = url.split('/').slice(-1)[0]

			// update record with email address
			axios.put(process.env.VUE_APP_UPDATEEMAIL_ENDPOINT, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
				url: url,
				respId: uuid,
				email: data.email,
				role: data.role
			})

		}
	}
});


