import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;


// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCircleDot)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// import styles
import '@/styles/site.scss';



// vuex
import { store } from './store/store.js';


// VueRouter
import VueRouter from 'vue-router';
import { routes } from './routes/routes.js';
Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'instant' });
  }
})


// cookies
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
